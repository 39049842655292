import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout/index";
import PDF1 from "../../content/21032024_Anúncio_RJ.pdf";
import PDF2 from "../../content/Perguntas&Respostas_22032024.pdf";
import PDF3 from "../../content/Relação de Credores da AJ - art. 7o, parágrafo 2o.pdf";
// import PDF4 from "../../content/1_Comunicado_Credores_RJ_HabilitaçoesDivergen.pdf";
// import PDF5 from "../../content/20240603_Condições_PRJ_Apresentado vDia.pdf";
import PDF6 from "../../content/(RJ Grupo Dia) Petição e Plano de Recuperação Judicial.pdf";
import PDF7 from "../../content/(Grupo Dia) Comunicação credores (1).pdf";
import PDF8 from "../../content/Impugnação Contra a Relação de Credores.docx.pdf";
import PDF9 from "../../content/Edital de Convocação Assembleia.pdf"
import "../styles/institucional.scss";

// Icons
import checkIcon from "../../images/institucional/check.svg";

const Banner = () => (
	<>
		<section className="container-fluid p-0 d-lg-block d-none">
			<div className="backgroud-banner-diaxpress w-100 d-flex align-items-center" />
		</section>
		<section className="container-fluid p-0 d-lg-none d-block">
			<div className="backgroud-banner w-100" />
		</section>
	</>
);

const PrincipiosCards = () => (
	<>
		<section className="d-flex justify-content-center my-0 mb-5 my-md-5 p-0">
			<div className="card-grid-equidade-relatorios">
				<a href={PDF1} target="_blank" rel="noopener noreferrer">
					<div className="card card-principios border-0">
						<img src={checkIcon} alt="icone de correto" />
						<p className="font-size-1">Anúncio</p>
					</div>
				</a>
				<a href={PDF2} target="_blank" rel="noopener noreferrer">
					<div className="card card-principios border-0">
						<img src={checkIcon} alt="icone de correto" />
						<p className="font-size-1">Perguntas & Respostas</p>
					</div>
				</a>
				<a href={PDF3} target="_blank" rel="noopener noreferrer">
					<div className="card card-principios border-0">
						<img src={checkIcon} alt="icone de correto" />
						<p className="font-size-1">
							Relação de Credores relativa ao Art. 7º § 2º da Lei 11.101/2005
						</p>
					</div>
				</a>
				{/* <a href={PDF4} target="_blank" rel="noopener noreferrer">
					<div className="card card-principios border-0">
						<img src={checkIcon} alt="icone de correto" />
						<p className="font-size-1">Habilitações e Divergências</p>
					</div>
				</a> */}
				{/* <a href={PDF5} target="_blank" rel="noopener noreferrer">
					<div className="card card-principios border-0">
						<img src={checkIcon} alt="icone de correto" />
						<p className="font-size-1">Plano RJ - Resumo das Condições</p>
					</div>
				</a> */}
				<a href={PDF6} target="_blank" rel="noopener noreferrer">
					<div className="card card-principios border-0">
						<img src={checkIcon} alt="icone de correto" />
						<p className="font-size-1">Plano RJ - Íntegra</p>
					</div>
				</a>
				<a href={PDF7} target="_blank" rel="noopener noreferrer">
					<div className="card card-principios border-0">
						<img src={checkIcon} alt="icone de correto" />
						<p className="font-size-1">Comunicação Credores - Procurador para Assembleia</p>
					</div>
				</a>
				<a href={PDF8} target="_blank" rel="noopener noreferrer">
					<div className="card card-principios border-0">
						<img src={checkIcon} alt="icone de correto" />
						<p className="font-size-1">Impugnação Contra a Relação de Credores</p>
					</div>
				</a>
				<a href={PDF9} target="_blank" rel="noopener noreferrer">
					<div className="card card-principios border-0">
						<img src={checkIcon} alt="icone de correto" />
						<p className="font-size-1">Edital de Convocação para Assembleia Geral de Credores</p>
					</div>
				</a>
			</div>
		</section>
	</>
);

const Recuperacaojudicial = () => {
	const breadC = {
		visible: true,
		color: "black",
		items: [
			{ path: "/", name: "Home" },
			// { path: '/institucional/', name: 'Institucional' },
			{
				path: "/institucional/recuperacaojudicial/",
				name: "Recuperação Judicial",
			},
		],
	};

	return (
		<Layout breadCrumb={breadC}>
			<Helmet>
				<html lang="pt-BR" amp />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<title>Recuperação Judicial - Dia Supermercados</title>
				<meta name="description" content="Recuperação Judicial" />
				<meta
					name="keywords"
					content="dia supermercado, Recuperação Judicial"
				/>
				<meta name="author" content="DIA Supermercados" />
				<meta
					property="og:image"
					content="https://www.dia.com.br/card-dia.png"
				/>
			</Helmet>
			<Banner />
			<section className="container-md d-flex flex-column align-items-center principios p-0 mb-2">
				<h2 className="mb-4" id="one" tabIndex={0}>
					Recuperação Judicial
				</h2>
			</section>
			<PrincipiosCards />
		</Layout>
	);
};
export default Recuperacaojudicial;
